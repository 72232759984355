import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const skeletonFn = /* @__PURE__ */ createRecipe('skeleton', {
  "variant": "rounded",
  "verticalSpacing": "small",
  "height": "small",
  "color": "normal",
  "width": "full"
}, [])

const skeletonVariantMap = {
  "color": [
    "normal",
    "inverted"
  ],
  "variant": [
    "circular",
    "rounded",
    "rectangular"
  ],
  "verticalSpacing": [
    "none",
    "small",
    "medium",
    "large"
  ],
  "height": [
    "small",
    "medium",
    "large",
    "xLarge",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "button",
    "caption",
    "overline",
    "blockquote",
    "code"
  ],
  "width": [
    "quarter",
    "half",
    "threeQuarters",
    "full"
  ]
}

const skeletonVariantKeys = Object.keys(skeletonVariantMap)

export const skeleton = /* @__PURE__ */ Object.assign(memo(skeletonFn.recipeFn), {
  __recipe__: true,
  __name__: 'skeleton',
  __getCompoundVariantCss__: skeletonFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: skeletonVariantKeys,
  variantMap: skeletonVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, skeletonVariantKeys)
  },
  getVariantProps: skeletonFn.getVariantProps,
})