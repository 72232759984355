import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const customFlexFn = /* @__PURE__ */ createRecipe('customflex', {
  "direction": "row",
  "align": "stretch",
  "justify": "start",
  "wrap": "noWrap"
}, [])

const customFlexVariantMap = {
  "direction": [
    "row",
    "column",
    "rowReverse",
    "columnReverse"
  ],
  "align": [
    "start",
    "center",
    "end",
    "stretch",
    "baseline"
  ],
  "justify": [
    "start",
    "center",
    "end",
    "between",
    "around"
  ],
  "wrap": [
    "noWrap",
    "wrap",
    "wrapReverse"
  ],
  "gap": [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10"
  ],
  "columngap": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10"
  ],
  "rowgap": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10"
  ]
}

const customFlexVariantKeys = Object.keys(customFlexVariantMap)

export const customFlex = /* @__PURE__ */ Object.assign(memo(customFlexFn.recipeFn), {
  __recipe__: true,
  __name__: 'customFlex',
  __getCompoundVariantCss__: customFlexFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: customFlexVariantKeys,
  variantMap: customFlexVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, customFlexVariantKeys)
  },
  getVariantProps: customFlexFn.getVariantProps,
})