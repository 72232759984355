import { withoutSpace } from '../helpers.mjs';

const conditionsStr = "_hover,_focus,_focusWithin,_focusVisible,_disabled,_active,_visited,_target,_readOnly,_readWrite,_empty,_checked,_enabled,_expanded,_highlighted,_before,_after,_firstLetter,_firstLine,_marker,_selection,_file,_backdrop,_first,_last,_only,_even,_odd,_firstOfType,_lastOfType,_onlyOfType,_peerFocus,_peerHover,_peerActive,_peerFocusWithin,_peerFocusVisible,_peerDisabled,_peerChecked,_peerInvalid,_peerExpanded,_peerPlaceholderShown,_groupFocus,_groupHover,_groupActive,_groupFocusWithin,_groupFocusVisible,_groupDisabled,_groupChecked,_groupExpanded,_groupInvalid,_indeterminate,_required,_valid,_invalid,_autofill,_inRange,_outOfRange,_placeholder,_placeholderShown,_pressed,_selected,_default,_optional,_open,_closed,_fullscreen,_loading,_currentPage,_currentStep,_motionReduce,_motionSafe,_print,_landscape,_portrait,_dark,_light,_osDark,_osLight,_highContrast,_lessContrast,_moreContrast,_ltr,_rtl,_scrollbar,_scrollbarThumb,_scrollbarTrack,_horizontal,_vertical,_starting,bp1,bp1Only,bp1Down,bp1.5,bp1.5Only,bp1.5Down,bp2,bp2Only,bp2Down,bp3,bp3Only,bp3Down,bp4,bp4Only,bp4Down,sm,smOnly,smDown,md,mdOnly,mdDown,lg,lgOnly,lgDown,xl,xlOnly,xlDown,2xl,2xlOnly,2xlDown,bp1ToBp1.5,bp1ToBp2,bp1ToBp3,bp1ToBp4,bp1ToSm,bp1ToMd,bp1ToLg,bp1ToXl,bp1To2xl,bp1.5ToBp2,bp1.5ToBp3,bp1.5ToBp4,bp1.5ToSm,bp1.5ToMd,bp1.5ToLg,bp1.5ToXl,bp1.5To2xl,bp2ToBp3,bp2ToBp4,bp2ToSm,bp2ToMd,bp2ToLg,bp2ToXl,bp2To2xl,bp3ToBp4,bp3ToSm,bp3ToMd,bp3ToLg,bp3ToXl,bp3To2xl,bp4ToSm,bp4ToMd,bp4ToLg,bp4ToXl,bp4To2xl,smToMd,smToLg,smToXl,smTo2xl,mdToLg,mdToXl,mdTo2xl,lgToXl,lgTo2xl,xlTo2xl,@/xs,@/sm,@/md,@/lg,@/xl,@/2xl,@/3xl,@/4xl,@/5xl,@/6xl,@/7xl,@/8xl,base"
const conditions = new Set(conditionsStr.split(','))

export function isCondition(value){
  return conditions.has(value) || /^@|&|&$/.test(value)
}

const underscoreRegex = /^_/
const conditionsSelectorRegex = /&|@/

export function finalizeConditions(paths){
  return paths.map((path) => {
    if (conditions.has(path)){
      return path.replace(underscoreRegex, '')
    }

    if (conditionsSelectorRegex.test(path)){
      return `[${withoutSpace(path.trim())}]`
    }

    return path
  })}

  export function sortConditions(paths){
    return paths.sort((a, b) => {
      const aa = isCondition(a)
      const bb = isCondition(b)
      if (aa && !bb) return 1
      if (!aa && bb) return -1
      return 0
    })
  }