import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const customDividerFn = /* @__PURE__ */ createRecipe('customDivider', {
  "size": "small",
  "orientation": "horizontal"
}, [
  {
    "orientation": "horizontal",
    "size": "small",
    "css": {
      "height": "1px",
      "width": "100%"
    }
  },
  {
    "orientation": "horizontal",
    "size": "medium",
    "css": {
      "height": "3px",
      "width": "100%"
    }
  },
  {
    "orientation": "horizontal",
    "size": "large",
    "css": {
      "height": "5px",
      "width": "100%"
    }
  },
  {
    "orientation": "vertical",
    "size": "small",
    "css": {
      "width": "1px"
    }
  },
  {
    "orientation": "vertical",
    "size": "medium",
    "css": {
      "width": "3px"
    }
  },
  {
    "orientation": "vertical",
    "size": "large",
    "css": {
      "width": "5px"
    }
  }
])

const customDividerVariantMap = {
  "orientation": [
    "horizontal",
    "vertical"
  ],
  "size": [
    "small",
    "medium",
    "large"
  ]
}

const customDividerVariantKeys = Object.keys(customDividerVariantMap)

export const customDivider = /* @__PURE__ */ Object.assign(memo(customDividerFn.recipeFn), {
  __recipe__: true,
  __name__: 'customDivider',
  __getCompoundVariantCss__: customDividerFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: customDividerVariantKeys,
  variantMap: customDividerVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, customDividerVariantKeys)
  },
  getVariantProps: customDividerFn.getVariantProps,
})