import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const customGridFn = /* @__PURE__ */ createRecipe('customgrid', {
  "columns": 1,
  "gap": "small"
}, [])

const customGridVariantMap = {
  "columns": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10"
  ],
  "gap": [
    "none",
    "tiny",
    "xsmall",
    "small",
    "medium",
    "large"
  ]
}

const customGridVariantKeys = Object.keys(customGridVariantMap)

export const customGrid = /* @__PURE__ */ Object.assign(memo(customGridFn.recipeFn), {
  __recipe__: true,
  __name__: 'customGrid',
  __getCompoundVariantCss__: customGridFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: customGridVariantKeys,
  variantMap: customGridVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, customGridVariantKeys)
  },
  getVariantProps: customGridFn.getVariantProps,
})