import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const gutterFn = /* @__PURE__ */ createRecipe('gutter', {
  "variant": "tall"
}, [])

const gutterVariantMap = {
  "variant": [
    "short",
    "tall",
    "bare",
    "horizontal"
  ],
  "modificiations": [
    "noTopPadding",
    "noBottomPadding"
  ]
}

const gutterVariantKeys = Object.keys(gutterVariantMap)

export const gutter = /* @__PURE__ */ Object.assign(memo(gutterFn.recipeFn), {
  __recipe__: true,
  __name__: 'gutter',
  __getCompoundVariantCss__: gutterFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: gutterVariantKeys,
  variantMap: gutterVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, gutterVariantKeys)
  },
  getVariantProps: gutterFn.getVariantProps,
})